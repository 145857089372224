<template>
  <div class="login_container">
    <!-- 中间 -->
    <van-cell-group>
      <div style="width: 100%;">
        <van-cell center style="padding:4px 16px">
          <template slot="icon">
            <van-dropdown-menu>
              <van-dropdown-item v-model="value" :options="option" @change="medicalDrop" />
            </van-dropdown-menu>
          </template>
          <template slot="title">
            <div style="text-align: center;">
              <span @click="showTime = true">{{date}}</span>
              <van-calendar
                v-model="showTime"
                type="range"
                @confirm="onConfirm"
                :defaultDate="defaultDate"
                :min-date="minDate"
                :max-date="maxDate"
                color="#57c4b7"
              />
            </div>
          </template>
          <template slot="right-icon">
            <div style="dispaly:flex" @click="onDateSort">
              日期排序
              <van-icon
                slot="right-icon"
                class="iconfont"
                :name="sortIcon"
                class-prefix="icon"
                color="#969799"
              />
            </div>
          </template>
        </van-cell>
        <van-cell>
          <template slot="title">
            <span style="color:#57c4b7; font-size:12px;">*温馨提示：微信医院仅能查询6个月内的记录，如需查询更早以前请到窗口查询。</span>
          </template>
        </van-cell>
      </div>
    </van-cell-group>

    <van-list>
      <van-cell-group v-for="item in list" :key="item.index">
        <van-cell center @click="onHFeeD(item.totalCost,item.endTimeYear,item.endTimeDay,item.dischargeDate)">
          <template slot="title">
            <div style="display:flex;">
              <div style="padding-right:24px; border-right: 2px solid #CCCCCC;">
                <span style="font-size:18px">{{item.endTimeYear}}年</span>
                <br />
                <span style="font-size:28px">{{item.endTimeDay}}</span>
              </div>
              <div style="padding-left:24px;">
                <span style="font-size:16px">费用小结</span>
                <br />
                <span style="font-size:18px">{{item.totalCost}}元</span>
              </div>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <div style="font-size: 12px; color: #D3D3D3; margin: 1% 40%; width: auto; height: 40px;"><span>&copy;拓实银医</span></div>
  </div>
</template>
<script>
import ajax from "../../lib/ajax";
import store from "../../store";
import { Toast } from "vant";
// let patientid = store.getters["store/patientid"];
let listss = [];
// patientid = "0000008866";
// let inpatientSeriNo = store.getters["store/inpatientSeriNo"];
var nowDate = new Date();
export default {
  data() {
    return {
      value: 0,
      showTime: false,
      date: "",
      list: [],
      defaultDate: [],
      minDate: new Date(
        nowDate.getFullYear(),
        nowDate.getMonth()-3,
        nowDate.getDate()
      ),
      maxDate: new Date(
        nowDate.getFullYear(),
        nowDate.getMonth(),
        nowDate.getDate()
      ),
      startTime: "",
      endTime: "",
      sortIcon: "caozuo-paixu-jiangxu", //caozuo-paixu-jiangxu、paixu-shengxu
      option: [
        {
          text: "全部",
          value: 0,
        },
        {
          text: "1个月内",
          value: 1,
        },
        {
          text: "6个月以内",
          value: 2,
        },
      ],
    };
  },
  created() {
    //初始化时间
    const lastDate = new Date(new Date().setDate(new Date().getDate() - 180));
    const date = [lastDate, new Date()];
    this.startTime =
      lastDate.getFullYear() +
      "" +
      (lastDate.getMonth() >= 9
        ? lastDate.getMonth() + 1
        : "0" + (lastDate.getMonth() + 1)) +
      lastDate.getDate();
      this.endTime =
        nowDate.getFullYear() +
        "" +
        (nowDate.getMonth() >= 9
          ? nowDate.getMonth() + 1
          : "0" + (nowDate.getMonth() + 1)) +
        nowDate.getDate();
      // console.log(this.startTime+"~"+this.endTime);
      this.onConfirm(date);
    this.defaultDate = date;
    this.onload();
  },
  methods: {
    async onload() {
      let idcard = store.getters["store/idcard"];
      // console.log(patientid);
      await ajax
      .get("/Api/InHospitalService/GetInHospitalInfo?input="+ idcard)
      .then((res) => {
        // const code = res.data.result.success;
        // .post("/Api/InHospitalService/GetInpatientDetail", {
        //   inpatientSeriNo: inpatientSeriNo,
        //   detail: true,
        //   updateTimeStart: this.startTime,
        //   updateTimeEnd: this.endTime,
        //   machineName: "wechat",
        // })
        // .then((res) => {
          listss = res.data.result.inpatientInfos;
          console.log(listss);
          this.selectDropTime();
          // this.list = res.data.result.inpatientInfos;
          // console.log(this.list);
          // for(let i=0; i<this.list.length; i++){
          //   this.list[i].endTimeYear = this.list[i].inpatientDate.substring(0,4);
          //   this.list[i].endTimeDay = this.list[i].inpatientDate.substring(4,6)+"-"+this.list[i].inpatientDate.substring(6,8);
          // }
          // console.log(this.list);
        })
        .catch((err) => {
          console.log(err.data);
          Toast("获取失败，请稍后重试！");
        });
    },
     medicalDrop() {
      if (this.value == 0 || this.value == 2) {
        const lastDate = new Date(
          new Date().setDate(new Date().getDate() - 180)
        );
        const date = [lastDate, new Date()];
        this.startTime =
          lastDate.getFullYear() +
          "" +
          (lastDate.getMonth() >= 9
            ? lastDate.getMonth() + 1
            : "0" + (lastDate.getMonth() + 1)) +
          lastDate.getDate();
        this.endTime =
          nowDate.getFullYear() +
          "" +
          (nowDate.getMonth() >= 9
            ? nowDate.getMonth() + 1
            : "0" + (nowDate.getMonth() + 1)) +
          nowDate.getDate();
        // console.log(this.startTime+"~"+this.endTime);
        this.defaultDate = date;
        this.onConfirm(date);
      } else if(this.value == 1 ){
        const lastDate = new Date(
          new Date().setDate(new Date().getDate() - 30)
        );
        const date = [lastDate, new Date()];
        this.startTime =
          lastDate.getFullYear() +
          "" +
          (lastDate.getMonth() >= 9
            ? lastDate.getMonth() + 1
            : "0" + (lastDate.getMonth() + 1)) +
          lastDate.getDate();
        this.endTime =
          nowDate.getFullYear() +
          "" +
          (nowDate.getMonth() >= 9
            ? nowDate.getMonth() + 1
            : "0" + (nowDate.getMonth() + 1)) +
          nowDate.getDate();
        console.log(this.startTime+"~"+this.endTime);
        this.defaultDate = date;
        this.onConfirm(date);
      }
    },
    formatDate(date) {
      return `${
        date.getMonth() >= 9 ? '-'+date.getMonth() + 1 : "-0" + (date.getMonth() + 1)
      }${date.getDate() > 10 ? '-'+date.getDate() : "-0" + date.getDate()}`;
    },
    onConfirm(date) {
      const [start, end] = date;
      this.showTime = false;
      this.startTime = `${start.getFullYear()}`+'-'+`${this.formatDate(start)}`;
      this.endTime = `${end.getFullYear()}`+'-'+`${this.formatDate(end)}`;
      console.log(this.startTime + "~" + this.endTime);
      this.date = `${
        start.getMonth() >= 9
          ? start.getMonth() + 1
          : "0" + (start.getMonth() + 1)
      }-${start.getDate()}至${
        end.getMonth() >= 9 ? end.getMonth() + 1 : "0" + (end.getMonth() + 1)
      }-${end.getDate()}`;
      this.selectDropTime();
    },
    //下拉全部菜单筛选、时间筛选、排序
    async selectDropTime() {
      // console.log(listss);
      // console.log(this.list);
      this.list = [];
      if (this.value == 0) {
        for (let i = 0; i < listss.length; i++) {
          if (
            this.DateContrast(this.startTime, listss[i].inpatientDate) &&
            this.DateContrast(listss[i].inpatientDate, this.endTime)
          ) {
            let index = listss[i];
            this.list.push(index);
          }
        }
      } else if (this.value == 1) {
        for (let i = 0; i < listss.length; i++) {
          if (
            this.DateContrast(this.startTime, listss[i].inpatientDate) &&
            this.DateContrast(listss[i].inpatientDate, this.endTime)
          ) {
            let index = listss[i];
            this.list.push(index);
          }
        }
      } else if (this.value == 2) {
        for (let i = 0; i < listss.length; i++) {
          if (
            this.DateContrast(this.startTime, listss[i].inpatientDate) &&
            this.DateContrast(listss[i].inpatientDate, this.endTime)
          ) {
            let index = listss[i];
            this.list.push(index);
          }
        }
      } else {
        Toast("出现未知错误!!!");
      }
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].endTimeYear = this.list[i].inpatientDate.substring(0, 4);
        this.list[i].endTimeDay =
          this.list[i].inpatientDate.substring(5, 10) 
          //+ "-" +
          // this.list[i].inpatientDate.substring(8, 10);
      }
      console.log(this.list);
    },
    DateContrast(start, end, flags) {
      console.log(end);
      const starttime = new Date(
        start.substring(0, 4),
        start.substring(5, 7),
        start.substring(8, 10)
      );
      const starttimes = starttime.getTime();
      console.log(end.substring(4, 6));
      const endTime = new Date(
        end.substring(0, 4),
        end.substring(5, 7),
        end.substring(8, 10)
      );
      const endTimes = endTime.getTime();
      if (endTimes >= starttimes) {
        flags = true;
        return flags;
      } else {
        flags = false;
        return flags;
      }
    },
    sortTime(obj1, obj2) {
      return obj2.tradeDate - obj1.tradeDate;
    },
    sortTime2(obj1, obj2) {
      return obj1.tradeDate - obj2.tradeDate;
    },
    onDateSort() {
      //时间排序
      if (this.sortIcon === "caozuo-paixu-jiangxu") {
        this.sortIcon = "paixu-shengxu";
        this.list.sort(this.startTime);
      } else {
        this.sortIcon = "caozuo-paixu-jiangxu";
        this.list.sort(this.startTime2);
      }
    },
     onHFeeD(totalCost,endTimeYear,endTimeDay,dischargeDate) {
      window.sessionStorage.setItem("totalCost",totalCost);
      window.sessionStorage.setItem("endTimeYear",endTimeYear);
      window.sessionStorage.setItem("endTimeDay",endTimeDay);
      // if(dischargeDate === null){
      //   dischargeDate = '';
      //   console.log(123);
      // } 
      window.sessionStorage.setItem("dischargeDate",dischargeDate);
      this.$router
        .replace({
          path: "/QHFeeD",
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>


<style lang="less" scoped>
.jj {
  padding-right: 24px;
  border-right: 2px solid black;
}
</style>
